import React from "react";
import { auth } from "./Firebase";
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import { AppleLoginButton } from "react-social-login-buttons";
import "../App.css";

const AppleSignIn = () => {
  const handleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");

    try {
      const result = await signInWithPopup(auth, provider);
      console.log("User Info: ", result.user);
    } catch (error) {
      console.error("Error Signing In: ", error);
    }
  };

  return <AppleLoginButton onClick={handleSignIn} size="50px" className="login-button" align="center"/>;
};

export default AppleSignIn;
